import * as React from "react"

// styles
const pageStyles = {
  // color: "#232129",
  padding: 96,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  textAlign: 'center'
  // maxWidth: 320,
}

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      {/* <title>Home Page</title> */}
      <h4 style={headingStyles}>
        Under Maintenance
      </h4>
    </main>
  )
}

export default IndexPage
